import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MeetingCategoriesApiActions } from '../actions/meeting-category.actions';
import {
  AdminMeetingQuestionBankPageActions,
  MeetingQuestionBankAPIActions,
  MeetingQuestionsErrorActions,
} from '../actions/meeting-question.actions';
import { IQuestionResult } from '../models/meeting-question.model';

export const meetingQuestionsFeatureKey = 'questions';

export interface MeetingQuestionState extends EntityState<IQuestionResult> {
  isLoaded: boolean;
  loading: boolean;
  selectedQuestionId: string;
}

export const meetingQuestionAdapter: EntityAdapter<IQuestionResult> =
  createEntityAdapter<IQuestionResult>();

export const meetingQuestionInitialState: MeetingQuestionState =
  meetingQuestionAdapter.getInitialState({
    isLoaded: false,
    loading: false,
    selectedQuestionId: null,
  });

export const meetingQuestionReducer = createReducer(
  meetingQuestionInitialState,
  on(
    MeetingQuestionBankAPIActions.loadMeetingQuestionsSuccess,
    (state, { questions }) =>
      meetingQuestionAdapter.setAll(questions, {
        ...state,
        loading: false,
        isLoaded: true,
        selectedQuestionId: null,
      }),
  ),
  on(
    AdminMeetingQuestionBankPageActions.addMeetingQuestion,
    AdminMeetingQuestionBankPageActions.patchMeetingQuestion,
    AdminMeetingQuestionBankPageActions.loadMeetingQuestions,
    AdminMeetingQuestionBankPageActions.deleteMeetingQuestion,
    (state) => ({ ...state, loading: true }),
  ),
  on(
    AdminMeetingQuestionBankPageActions.selectMeetingQuestion,
    (state, { id }) => ({ ...state, selectedQuestionId: id }),
  ),
  on(
    AdminMeetingQuestionBankPageActions.resetSelectedMeetingQuestion,
    (state) => ({ ...state, selectedQuestionId: null }),
  ),
  on(
    AdminMeetingQuestionBankPageActions.addMeetingQuestion,
    (state, { question }) => ({
      ...state,
      selectedQuestionId: null,
    }),
  ),
  on(
    AdminMeetingQuestionBankPageActions.patchMeetingQuestion,
    (state, { questionId }) => ({
      ...state,
      selectedQuestionId: questionId,
    }),
  ),
  on(
    MeetingQuestionBankAPIActions.addMeetingQuestionSuccess,
    (state, { question }) => {
      return meetingQuestionAdapter.addOne(question, {
        ...state,
        selectedQuestionId: question.id,
        loading: false,
      });
    },
  ),
  on(
    MeetingQuestionBankAPIActions.patchMeetingQuestionSuccess,
    (state, { question }) =>
      meetingQuestionAdapter.updateOne(
        { id: question.id, changes: question },
        {
          ...state,
          selectedQuestionId: question.id,
          loading: false,
        },
      ),
  ),
  on(
    MeetingQuestionBankAPIActions.deleteMeetingQuestionSuccess,
    (state, { id }) =>
      meetingQuestionAdapter.removeOne(id, {
        ...state,
        loading: false,
        selectedQuestionId: null,
      }),
  ),

  on(MeetingQuestionsErrorActions.errorQuestion, (state, _) => ({
    ...state,
    loading: false,
  })),

  // UI ACTIONS
  on(AdminMeetingQuestionBankPageActions.createMeetingQuestionUI, (state) => ({
    ...state,
    selectedQuestionId: null,
  })),

  // Category ACTIONS
  on(
    MeetingCategoriesApiActions.deleteMeetingCategorySuccess,
    (state, { id }) => {
      const selectedQuestion = state.entities[state.selectedQuestionId];
      return {
        ...state,
        selectedQuestionId:
          selectedQuestion?.categoryId === id ? null : state.selectedQuestionId,
      };
    },
  ),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  meetingQuestionAdapter.getSelectors();
