import { Segment } from './segments.model';
import { EUserHiringFilter, TeamMember, User, UserFull } from './user.model';

// LEGACY FILTERS
export type DepthValue = -1 | 1 | 10;

export interface FiltersLegacy {
  viewAsUser: UserFull;
  depth: DepthValue;
  segments: Map<number, Segment[]>;
}

export type Scope = 'company' | 'department' | 'team' | 'directReports';

export interface FiltersParamsLegacy {
  viewAsUserId: string;
  depth: string;
  'segmentIds[]': string[];
}

export interface RawParams {
  viewAsUserId?: number;
  depth?: number;
  segmentIds?: string[];
}

// FILTERS
export enum FiltersScopeTypeEnum {
  COMPANY = 'COMPANY',
  HIERARCHY = 'HIERARCHY',
  MATRIX = 'MATRIX',
}
export enum FiltersDepthEnum {
  TEAMS = 'TEAMS',
  N1 = 'N-1',
}

export interface FiltersCompanyScope {
  type: FiltersScopeTypeEnum.COMPANY;
}
export interface FiltersHierarchyScope {
  type: FiltersScopeTypeEnum.HIERARCHY;
  viewAsUser?: User;
  viewAsUserId?: number;
  depth: FiltersDepthEnum;
}
export interface FiltersMatrixScope {
  type: FiltersScopeTypeEnum.MATRIX;
}

export type FiltersScope =
  | FiltersCompanyScope
  | FiltersHierarchyScope
  | FiltersMatrixScope;

export interface SegmentationContent {
  segmentTypeId: number;
  segmentIds: number[];
}
export type Segmentation = SegmentationContent[];

export interface Filters {
  scope: FiltersScope;
  segmentation: Segmentation;
}

export interface FiltersScopeLayout {
  id?: string;
  type?: FiltersScopeTypeEnum;
  viewAsUser?: User | TeamMember;
  viewAsUserId?: number;
  depth?: FiltersDepthEnum;
}

export interface IFilterFindUsers {
  email?: string;
  firstname?: string;
  lastname?: string;
  roleId?: number;
  isBleexoAccount?: boolean;
  hiring?: EUserHiringFilter[];
  revieweeIdentitySearch?: string;
  identitySearch?: string;
  ids?: number[];
  reviewerIds?: number[];
  revieweeIds?: number[];
  companyId?: string;
  externalEmployeeId?: string;
  externalUserId?: string;
  externalId?: string; // allows to search for externalEmployeeId OR externalUserId
  page?: number;
  size?: number;
  order?: string;
  canBeReviewee?: boolean;
  forcedReviewerIds?: string; // We will need it later
}
