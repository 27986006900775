import { Injectable } from '@angular/core';
import { MeetingTypeService } from '@meeting/services/meeting-type.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromMeetingTypesActions from '../actions/meeting-type.actions';
import { IType } from '../models/meeting-type.interface';

@Injectable()
export class MeetingTypesEffects {
  constructor(
    private _actions$: Actions,
    private _meetingTypeService: MeetingTypeService,
  ) {}

  /**
   * * LOAD *
   */
  loadMeetingTypes$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromMeetingTypesActions.MeetingTypesActions.loadMeetingTypes),
      switchMap(() =>
        this._meetingTypeService.getAll$().pipe(
          map((meetingTypes: IType[]) =>
            fromMeetingTypesActions.MeetingTypesApiActions.loadMeetingTypesSuccess(
              { meetingTypes },
            ),
          ),
          catchError((error) =>
            of(
              fromMeetingTypesActions.MeetingTypesErrorAction.errorMeetingType({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );
}
