import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

const flyInOut = 'cubic-bezier(0,1.1,.24,1)';
export interface ToastPreset {
  color: 'green' | 'red';
  icon: 'toast-success' | 'toast-info' | 'toast-error';
  title: 'SUCCESS' | 'INFO' | 'ERROR';
}

export const toastPresets: {
  success: ToastPreset;
  info: ToastPreset;
  error: ToastPreset;
} = {
  success: {
    color: 'green',
    icon: 'toast-success',
    title: 'SUCCESS',
  },
  info: {
    color: 'green',
    icon: 'toast-info',
    title: 'INFO',
  },
  error: {
    color: 'red',
    icon: 'toast-error',
    title: 'ERROR',
  },
};

export const toastAnim = trigger('flyInOut', [
  state(
    'inactive',
    style({
      opacity: 0,
    }),
  ),
  transition(
    'inactive => active',
    animate(
      `400ms ${flyInOut}`,
      keyframes([
        style({
          transform: 'translateY(50%)',
          opacity: 0,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]),
    ),
  ),
  transition(
    'active => removed',
    animate(
      `400ms ${flyInOut}`,
      keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translateY(50%)',
          opacity: 0,
        }),
      ]),
    ),
  ),
]);
