import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { toastAnim, ToastPreset, toastPresets } from './toast-vars';

@Component({
  selector: '[success-toast]',
  styleUrls: ['./toast-styles.scss'],
  template: `
    <div class="custom-toast shadow-xl flex items-start mb-2.5">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.7571 8.23016C15.0568 7.94425 15.068 7.4695 14.7821 7.1698C14.4962 6.87009 14.0214 6.85892 13.7217 7.14484L8.58387 12.0464L6.27842 9.84319C5.97896 9.55702 5.50421 9.56779 5.21803 9.86725C4.93186 10.1667 4.94263 10.6415 5.24209 10.9276L8.06527 13.6256C8.35499 13.9024 8.81118 13.9026 9.10114 13.626L14.7571 8.23016Z"
          fill="#6DC967"
        />
      </svg>

      <div class="grow success-text" style="overflow: hidden">
        <!-- <h5 class="mb-1.25">
          {{ title || 'STATUS.' + successPresets.title | transloco }}
        </h5> -->
        <p *ngIf="message">{{ message }}</p>
        <ng-content #download></ng-content>
      </div>

      <div (click)="close($event)" class="cursor-pointer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0303 5.03033C20.3232 4.73744 20.3232 4.26256 20.0303 3.96967C19.7374 3.67678 19.2626 3.67678 18.9697 3.96967L12 10.9393L5.03033 3.96967C4.73744 3.67678 4.26256 3.67678 3.96967 3.96967C3.67678 4.26256 3.67678 4.73744 3.96967 5.03033L10.9393 12L3.96967 18.9697C3.67678 19.2626 3.67678 19.7374 3.96967 20.0303C4.26256 20.3232 4.73744 20.3232 5.03033 20.0303L12 13.0607L18.9697 20.0303C19.2626 20.3232 19.7374 20.3232 20.0303 20.0303C20.3232 19.7374 20.3232 19.2626 20.0303 18.9697L13.0607 12L20.0303 5.03033Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  `,
  animations: [toastAnim],
})
export class SuccessToastComponent extends Toast {
  @Input() toastId: number;
  @Input() icon: string;
  successPresets: ToastPreset;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.successPresets = toastPresets.success;
  }

  close(event): void {
    event.stopPropagation();
    this.toastrService.remove(this.toastId);
  }
}
