import { Component, Input } from '@angular/core';
import { BleexoModule } from '@models/permissions.model';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { toastAnim, ToastPreset, toastPresets } from './toast-vars';

@Component({
  selector: '[download-toast]',
  styleUrls: ['./toast-styles.scss'],
  template: `
    <div class="custom-toast shadow-xl flex items-start mb-2.5">
      <svg-icon
        icon="toast-success"
        class="solid text-base text-green-4 mr-2.5"
      ></svg-icon>

      <div class="grow success-text" style="overflow: hidden">
        <!-- <h5 class="mb-1.25">
          {{ title || 'STATUS.' + presets.title | transloco }}
        </h5> -->
        <p *ngIf="message">{{ message }}</p>
        <p class="truncate mb-1.25">
          {{ 'STATUS.DOWNLOAD_READY' | transloco }}
          <span class="text-note">{{ fileName }}</span>
        </p>
        <a
          target="_blank"
          [href]="url"
          [download]="fileName"
          (click)="close($event)"
          class="{{ module }}-theme"
        >
          <button mat-flat-button color="primary">
            {{ 'GLOBAL.DOWNLOAD' | transloco | capitalize }}
            <i class="material-icons ml-2.5">file_download</i>
          </button>
        </a>
      </div>
      <div (click)="close($event)" class="cursor-pointer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0303 5.03033C20.3232 4.73744 20.3232 4.26256 20.0303 3.96967C19.7374 3.67678 19.2626 3.67678 18.9697 3.96967L12 10.9393L5.03033 3.96967C4.73744 3.67678 4.26256 3.67678 3.96967 3.96967C3.67678 4.26256 3.67678 4.73744 3.96967 5.03033L10.9393 12L3.96967 18.9697C3.67678 19.2626 3.67678 19.7374 3.96967 20.0303C4.26256 20.3232 4.73744 20.3232 5.03033 20.0303L12 13.0607L18.9697 20.0303C19.2626 20.3232 19.7374 20.3232 20.0303 20.0303C20.3232 19.7374 20.3232 19.2626 20.0303 18.9697L13.0607 12L20.0303 5.03033Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  `,
  animations: [toastAnim],
})
export class DownloadToastComponent extends Toast {
  @Input() url: string;
  @Input() fileName: string;
  @Input() toastId: number;
  @Input() module: BleexoModule;
  presets: ToastPreset;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.presets = toastPresets.success;
  }

  close(event): void {
    event.stopPropagation();
    this.toastrService.remove(this.toastId);
  }
}
