import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreState } from '@core/store/reducers/core.reducer';
import {
  selectCompany,
  selectUserPermissions,
} from '@core/store/selectors/core.selectors';
import { environment } from '@env/environment';
import { Company } from '@models/company.model';
import {
  ModulePermission,
  Permission,
  UserPermissions,
  UserRole,
} from '@models/permissions.model';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  _userPermissions: Permission[] = [];
  _company: Company;
  _isReady$$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private store: Store<CoreState>,
  ) {
    this.store
      .pipe(select(selectUserPermissions))
      .subscribe((userPermissions) => {
        this._userPermissions = userPermissions ?? [];
        this._isReady$$.next(true);
      });
    this.store.pipe(select(selectCompany)).subscribe((company) => {
      this._company = company;
    });
  }

  validateAllPermissions(permissions: Permission[]): boolean {
    return permissions.every((permission) =>
      this._userPermissions.includes(permission),
    );
  }

  validateOneOfPermissions(permissions: UserPermissions): boolean {
    return permissions.some((permission) =>
      this._userPermissions.includes(permission),
    );
  }

  companyHasModules(modules: ModulePermission[]): boolean {
    return this._company && modules.every((module) => this._company[module]);
  }

  companyHasOneOfModules(modules: ModulePermission[]): boolean {
    return this._company && modules.some((module) => this._company[module]);
  }

  companyHasFeature(flag: string): boolean {
    return this._company.featureFlags.hasOwnProperty(flag);
  }

  companyHasFeatureVersion(flag: string, version: string): boolean {
    return (
      this._company.featureFlags.hasOwnProperty(flag) &&
      this._company.featureFlags[flag] === version
    );
  }

  companyHasOneOfFeatureVersions(flag: string, versions: string[]): boolean {
    return (
      this._company.featureFlags.hasOwnProperty(flag) &&
      versions.includes(this._company.featureFlags[flag])
    );
  }

  getRoles(): Observable<UserRole[]> {
    return this.http
      .get<UserRole[]>(`${environment.API_URL}/core/roles`)
      .pipe(retry(1));
  }

  getRole(roleId): Observable<UserRole> {
    return this.http
      .get<UserRole>(`${environment.API_URL}/core/roles/` + roleId)
      .pipe(retry(1));
  }

  updateRole(role: UserRole): Observable<void> {
    const { id, name, permissions } = role;
    return this.http
      .patch<void>(`${environment.API_URL}/core/roles/${id}`, {
        name,
        permissions,
      })
      .pipe(retry(1));
  }

  createRole(role: UserRole): Observable<number> {
    const { name, permissions } = role;
    return this.http
      .post<UserRole>(`${environment.API_URL}/core/roles`, {
        name,
        permissions,
      })
      .pipe(
        retry(1),
        map((data) => data.id),
      );
  }

  deleteRole(id: number, fallbackId: number): Observable<boolean> {
    return this.http
      .delete(
        `${environment.API_URL}/core/roles/${id}?fallbackRoleId=${fallbackId}`,
        {
          observe: 'response',
        },
      )
      .pipe(
        retry(1),
        map((apiRes) => apiRes.ok),
      );
  }
}
