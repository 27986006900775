import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { toastAnim, ToastPreset, toastPresets } from './toast-vars';

@Component({
  selector: '[info-toast]',
  styleUrls: ['./toast-styles.scss'],
  template: `
    <div class="custom-toast info-toast flex items-start mb-2.5">
      <svg-icon
        icon="toast-info"
        class="solid text-base toast-icon mr-2.5"
      ></svg-icon>

      <div class="grow" style="overflow: hidden">
        <!-- <h5 class="mb-1.25">
          {{ title || 'STATUS.' + infoPresets.title | transloco }}
        </h5> -->
        <p *ngIf="message" class="flex items-end info-text">
          <span>{{ message }}</span>
          <horizontal-loader></horizontal-loader>
        </p>
        <p *ngIf="additionalMessage">
          <span class="text-note">{{ additionalMessage }}</span>
        </p>
        <ng-content #download></ng-content>
      </div>
      <div (click)="close($event)" class="cursor-pointer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.0303 5.03033C20.3232 4.73744 20.3232 4.26256 20.0303 3.96967C19.7374 3.67678 19.2626 3.67678 18.9697 3.96967L12 10.9393L5.03033 3.96967C4.73744 3.67678 4.26256 3.67678 3.96967 3.96967C3.67678 4.26256 3.67678 4.73744 3.96967 5.03033L10.9393 12L3.96967 18.9697C3.67678 19.2626 3.67678 19.7374 3.96967 20.0303C4.26256 20.3232 4.73744 20.3232 5.03033 20.0303L12 13.0607L18.9697 20.0303C19.2626 20.3232 19.7374 20.3232 20.0303 20.0303C20.3232 19.7374 20.3232 19.2626 20.0303 18.9697L13.0607 12L20.0303 5.03033Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  `,
  animations: [toastAnim],
})
export class InfoToastComponent extends Toast {
  @Input() toastId: number;
  @Input() icon: string;
  infoPresets: ToastPreset;
  additionalMessage = null;

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
    this.infoPresets = toastPresets.info;
  }

  close(event): void {
    event.stopPropagation();
    this.toastrService.remove(this.toastId);
  }
}
