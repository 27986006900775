import { Step } from '@meeting/components/meeting-survey-timeline-step/meeting-survey-timeline-step.component';
import { EMeetingStatuses } from '@meeting/store/models/meeting.model';

export enum EMeetingStepName {
  CREATION = 'CREATION',
  PREPARE = 'PREPARE',
  CONDUCT = 'CONDUCT',
  SIGN = 'SIGNATURE',
  REPORT = 'REPORT'
}

export type MeetingTimelineStep = Step & {
  possibleStatuses: EMeetingStatuses[];
};


export const meetingTimelineAdminSteps: MeetingTimelineStep[] = [
  {
    name: EMeetingStepName.CREATION,
    state: null,
    possibleStatuses: [EMeetingStatuses.CREATED],
    textKey: 'MEETING.TIMELINE.CREATED',
  },
  {
    name: EMeetingStepName.PREPARE,
    state: null,
    possibleStatuses: [
      EMeetingStatuses.STARTED,
      EMeetingStatuses.PREPARED_BY_REVIEWER,
      EMeetingStatuses.PREPARED_BY_REVIEWEE,
    ],
    textKey: 'MEETING.TIMELINE.PREPARE',
    textExtra: '',
    tooltip: '',
  },
  {
    name: EMeetingStepName.CONDUCT,
    state: null,
    possibleStatuses: [EMeetingStatuses.PREPARED_BY_BOTH],
    textKey: 'MEETING.TIMELINE.CONDUCT',
  },
  {
    name: EMeetingStepName.SIGN,
    state: null,
    possibleStatuses: [
      EMeetingStatuses.READY_TO_SIGN,
      EMeetingStatuses.FINISHED_BY_REVIEWER,
      EMeetingStatuses.FINISHED_BY_REVIEWEE,
    ],
    textKey: 'MEETING.TIMELINE.SIGN',
    textExtra: '',
    tooltip: '',
  },
  {
    name: EMeetingStepName.REPORT,
    state: null,
    possibleStatuses: [EMeetingStatuses.CLOSED_BY_BOTH],
    textKey: `MEETING.TIMELINE.REPORT`,
  },
];
