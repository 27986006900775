import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PermissionsService } from '@core/services/permissions.service';
import { selectUserId } from '@core/store/selectors/core.selectors';
import { environment } from '@env/environment';
import { ApiData, Meta } from '@models/API.models';
import { Filters, IFilterFindUsers } from '@models/filters.model';
import { TeamMember, User, UserFull } from '@models/user.model';
import { Store, select } from '@ngrx/store';
import * as qs from 'qs';
import { Observable, combineLatest, of } from 'rxjs';
import { map, retry, startWith, switchMap } from 'rxjs/operators';
import { FiltersBaseService } from './filters-base.service';
import { SegmentsService } from './segments.service';

interface UserQuery {
  search?: string;
  isManager?: boolean;
  onlyUnderMe?: boolean;
  limit?: number;
  skip?: number;
  orderBy: {
    property: 'lastname' | 'roleId' | 'isActive';
    direction: 'asc' | 'desc';
  };
  excludeCurrentUser?: boolean;
  excludeIds?: number[];
}

interface FindUsers {
  body: {
    depth?: number;
    segmentIds?: string[];
    isManager?: boolean;
    excludeIds?: number[];
    roleIds?: number[];
    search?: {
      text: string;
      properties: string[];
    };
    locale?: string[];
    isActive?: boolean;
  };
  meta: {
    skip?: number;
    limit?: number;
    orderBy?: {
      property: 'lastname' | 'roleId' | 'isActive';
      direction: 'asc' | 'desc';
    };
    onlyCount?: boolean;
    viewAsUserId?: number;
  };
}
export interface UserFindOptions {
  retrieveRoleFromRoleId?: boolean;
  retrieveManagerFromManagerId?: boolean;
  retrieveSegmentsFromSegmentIds?: boolean;
  retrieveManagedSemegentsFromManagedSegmentIds?: boolean;
  applyUserFindOptionsToManager?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UsersService extends FiltersBaseService {
  currentUserId = null;

  constructor(
    private http: HttpClient,
    private _permissionService: PermissionsService,
    private _segmentsService: SegmentsService,
    store: Store,
  ) {
    super(store);
    store
      .pipe(select(selectUserId))
      .subscribe((id) => (this.currentUserId = id));
  }

  // TODO remove when matrixOrgaRollupFF models are final to avoid regressions
  getLegacy(id: number): Observable<UserFull> {
    return this.http
      .get<ApiData<UserFull, Meta>>(`${environment.API_URL}/core/users/${id}`)
      .pipe(retry(1))
      .pipe(map((apiRes) => apiRes.data));
  }

  populateUser(
    user$: Observable<User>,
    options?: UserFindOptions,
    fromData?: User[],
  ): Observable<User> {
    return user$.pipe(
      switchMap((user) => {
        if (!options?.retrieveRoleFromRoleId) return of(user);
        return this._permissionService.getRole(user.roleId).pipe(
          map((role) => {
            return {
              ...user,
              role,
            };
          }),
        );
      }),
      // Retrieve Manager
      // switchMap((user) => {
      //   if (!(user.managerId && options?.retrieveManagerFromManagerId))
      //     return of(user);
      //   if (fromData) {
      //     return of({
      //       ...user,
      //       manager: fromData.find((m) => m.id === user.managerId),
      //     });
      //   }
      //   return this.get(
      //     user.managerId,
      //     options?.applyUserFindOptionsToManager ? options : undefined,
      //   ).pipe(
      //     map((manager) => {
      //       return {
      //         ...user,
      //         manager,
      //       };
      //     }),
      //   );
      // }),
      // Retrieve Segments
      switchMap((user) => {
        if (!options?.retrieveSegmentsFromSegmentIds) return of(user);
        return this._segmentsService.getUserSegmentIds(user.id).pipe(
          switchMap((segmentIds) => {
            if (!segmentIds) return of(user);
            const segments$ = segmentIds.map((segmentId) =>
              this._segmentsService.getSegment(segmentId),
            );
            return combineLatest(segments$).pipe(
              startWith([]),
              map((segments) => {
                return {
                  ...user,
                  segments,
                };
              }),
            );
          }),
        );
      }),
      // Retrieve Managed Segments
      switchMap((user: User) => {
        if (!options?.retrieveManagedSemegentsFromManagedSegmentIds)
          return of(user);
        return this._segmentsService.getUserManagedSegmentIds(user.id).pipe(
          switchMap((managedSegmentIds) => {
            if (!managedSegmentIds) return of(user);
            const managedSegments$ = managedSegmentIds.map((managedSegmentId) =>
              this._segmentsService.getSegment(managedSegmentId),
            );

            return combineLatest(managedSegments$).pipe(
              startWith([]),
              map((managedSegments) => {
                return {
                  ...user,
                  managedSegments,
                  managedSegmentIds: managedSegments.map((ms) => ms.id),
                };
              }),
            );
          }),
        );
      }),
    );
  }

  /**
   *
   * @param id user id
   * @param options
   * retrieveRoleFromRoleId: retrieve role from roleId
   *
   * retrieveManagerFromManagerId: retrieve manager from managerId
   *
   * retrieveSegmentsFromSegmentIds: retrieve segments from segmentIds
   *
   * retrieveManagedSemegentsFromManagedSegmentIds: retrieve managedSegments from managedSegmentIds
   *
   * applyUserFindOptionsToManager: apply this options to manager.
   * ie. find manager of manager etc if 'retrieveManagerFromManagerId' is true
   * @returns
   */
  get(id: number, options?: UserFindOptions): Observable<User> {
    return this.populateUser(
      this.http.get<User>(`${environment.API_URL}/core/users/${id}`),
      options,
    );
  }

  /*
  |--------------------------------------------------
  | ADMIN
  |--------------------------------------------------
  */
  // TODO keep only 1 prop type when matrixOrgaRollupFF models are final
  create(payload: Partial<UserFull | User>): Observable<number> {
    return this.http
      .post<
        ApiData<{ id: number }, Meta>
      >(`${environment.API_URL}/admin/users/`, payload, { observe: 'response' })
      .pipe(
        retry(1),
        map((apiRes) => apiRes.body.data.id),
      );
  }

  // TODO keep only 1 prop type when matrixOrgaRollupFF models are final
  update(id: number, payload: Partial<UserFull | User>): Observable<boolean> {
    return this.http
      .patch<
        HttpResponse<void>
      >(`${environment.API_URL}/core/users/${id}`, payload, { observe: 'response' })
      .pipe(
        retry(1),
        map((apiRes) => apiRes.ok),
      );
  }

  delete(id: number): Observable<boolean> {
    return this.http
      .delete(`${environment.API_URL}/admin/users/${id}`, {
        observe: 'response',
      })
      .pipe(
        retry(1),
        map((apiRes) => apiRes.ok),
      );
  }

  userStatus(id, status): Observable<any> {
    return this.http
      .patch(`${environment.API_URL}/admin/users/${id}`, {
        isActive: status,
      })
      .pipe(retry(1));
  }

  /*
  |--------------------------------------------------
  | SEARCHES
  |--------------------------------------------------
  */

  getUsersChunk(query: UserQuery): Observable<any> {
    // if (!this.permissions.validateAllPermissions(['scope:company'])) {
    //   query.onlyUnderMe = true
    // }
    return this.http
      .get(
        `${environment.API_URL}/admin/users?orderBy[property]=${query.orderBy.property}&orderBy[direction]=${query.orderBy.direction}`,
        {
          params: {
            ...(query.search ? { search: query.search } : {}),
            ...(query.isManager
              ? { isManager: query.isManager.toString() }
              : {}),
            ...(query.onlyUnderMe
              ? { onlyUnderMe: query.onlyUnderMe.toString() }
              : {}),
            ...(query.limit ? { limit: query.limit.toString() } : {}),
            ...(query.skip ? { skip: query.skip.toString() } : {}),
          },
        },
      )
      .pipe(retry(1));
  }

  findUsersWithoutStore(
    meta: { skip?: number; limit?: number; onlyCount?: boolean },
    filters: Filters,
  ): Observable<ApiData<TeamMember[], Meta>> {
    return this.http
      .get<ApiData<TeamMember[], Meta>>(
        `${environment.API_URL}/commons/users?${qs.stringify({
          meta: {
            skip: meta.skip,
            limit: meta.limit || 20,
            onlyCount: meta.onlyCount || false,
          },
          body: { filters: filters },
        })}`,
      )
      .pipe(retry(1));
  }

  findUsers(skip: number, limit = 20): Observable<ApiData<TeamMember[], Meta>> {
    return this.http
      .get<ApiData<TeamMember[], Meta>>(
        `${environment.API_URL}/commons/users?${qs.stringify({
          meta: { skip, limit },
          body: this.filtersObject,
        })}`,
      )
      .pipe(retry(1));
  }

  findUsersLegacy(query: FindUsers): Observable<ApiData<TeamMember[], Meta>> {
    const queryParams = qs.stringify({
      body: {
        ...(query.body.depth ? { depth: query.body.depth.toString() } : {}),
        ...(query.body.segmentIds ? { segmentIds: query.body.segmentIds } : {}),
        ...(query.body.isManager
          ? { isManager: query.body.isManager.toString() }
          : {}),
        ...(query.body.excludeIds ? { excludeIds: query.body.excludeIds } : {}),
        ...(query.body.roleIds ? { roleIds: query.body.roleIds } : {}),
        ...(query.body.search ? { search: query.body.search } : {}),
        ...(query.body.locale ? { locale: query.body.locale } : {}),
        ...(query.body.isActive
          ? { isActive: query.body.isActive.toString() }
          : {}),
      },
      meta: {
        ...(query.meta.skip !== undefined
          ? { skip: query.meta.skip.toString() }
          : {}),
        ...(query.meta.limit ? { limit: query.meta.limit.toString() } : {}),
        ...(query.meta.orderBy ? { orderBy: query.meta.orderBy } : {}),
        ...(query.meta.onlyCount
          ? { onlyCount: query.meta.onlyCount.toString() }
          : {}),
        ...(query.meta.viewAsUserId
          ? { viewAsUserId: query.meta.viewAsUserId.toString() }
          : {}),
      },
    });
    return this.http
      .get<
        ApiData<TeamMember[], Meta>
      >(`${environment.API_URL}/commons/users?${queryParams}`)
      .pipe(retry(1));
  }

  searchUsers(query: UserQuery): Observable<any> {
    let excludes = [];
    if (query.excludeCurrentUser) {
      excludes.push(this.currentUserId);
    }
    if (query.excludeIds && query.excludeIds.length) {
      excludes = [...excludes, ...query.excludeIds];
    }
    const queryParams = qs.stringify({
      ...(query.search ? { search: query.search } : {}),
      excludes,
      ...(query.isManager ? { isManager: query.isManager.toString() } : {}),
      ...(query.onlyUnderMe
        ? { onlyUnderMe: query.onlyUnderMe.toString() }
        : {}),
      ...(query.limit ? { limit: query.limit.toString() } : {}),
      ...(query.skip ? { skip: query.skip.toString() } : {}),
      orderBy: query.orderBy,
    });
    return this.http
      .get(`${environment.API_URL}/admin/users?${queryParams}`)
      .pipe(retry(1));
  }

  findManagers(search: string, depth?: string): Observable<User[]> {
    const body: { search?: string; depth?: number } = {};
    if (search) {
      body.search = search;
    }

    if (this.matrixOrgaRollupFF && depth !== undefined) {
      if (depth === 'TEAMS') {
        body.depth = 10;
      } else {
        body.depth = 1;
      }
    }

    return this.http
      .get<ApiData<User[], Meta>>(
        `${environment.API_URL}/commons/managers?${qs.stringify({
          body,
          meta: {
            orderBy: [{ property: 'lastname', direction: 'asc' }],
            skip: 0,
            limit: 20,
          },
        })}`,
      )
      .pipe(map((apiRes) => apiRes.data));
  }

  getAll$(options?: UserFindOptions, filters?: IFilterFindUsers) {
    let endpoint = `${environment.API_URL}/core/users`;
    if (filters) {
      endpoint += '?' + qs.stringify(filters);
    }
    return this.http
      .get<{
        data: User[];
      }>(endpoint)
      .pipe(
        switchMap((res) => {
          if (res.data?.length === 0) return of(res.data);
          const populatedUsers$ = (res.data ?? []).map((user) =>
            this.populateUser(of(user), options, res.data),
          );
          return combineLatest(populatedUsers$);
        }),
      );
  }
  getAllWithPagination$(
    options?: UserFindOptions,
    filters?: IFilterFindUsers,
    fromData?: User[],
  ) {
    let endpoint = `${environment.API_URL}/core/users`;
    if (filters) {
      endpoint += '?' + qs.stringify(filters);
    }
    return this.http
      .get<{
        data: User[];
        pagination: { total: number; page: number; totalPages: number };
      }>(endpoint)
      .pipe(
        switchMap((res) => {
          if (res.data?.length === 0)
            return of({
              users: res.data,
              pagination: res.pagination,
            });
          const populatedUsers$ = (res.data ?? []).map((user) =>
            this.populateUser(of(user), options, fromData),
          );
          return combineLatest(populatedUsers$).pipe(
            map((populatedUsers) => {
              return {
                users: populatedUsers,
                pagination: res.pagination,
              };
            }),
          );
        }),
      );
  }
}
